import { useState } from 'react'
import { Link } from 'react-router-dom'

function SidebarMenu({
  ciudad,
  ciudades,
  zona,
  zonas,
  onCiudadChange,
  onZonaChange,
  onPanellClose
}) {
  const [menuOpen, setMenuOpen] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
    if (menuOpen) onPanellClose()
  }
  const handleCiudadChange = (event) => {
    const selectedCiudadID = event.target.value
    let ciudadId = parseInt(selectedCiudadID)
    onCiudadChange(ciudadId)
  }
  const handleZonaChange = (event) => {
    const selectedZonaID = event.target.value
    let zonaId = parseInt(selectedZonaID)
    onZonaChange(zonaId)
  }

  return (
    <div>
      <button className='headerButton' onClick={toggleMenu}>
        <svg className='svgIcon' viewBox='0 0 100 100' width='36' height='24'>
          <rect y='10' width='100' height='12'></rect>
          <rect y='45' width='100' height='12'></rect>
          <rect y='80' width='100' height='12'></rect>
        </svg>
      </button>
      <div className={menuOpen ? 'hamMenuCanvasShow' : 'hamMenuCanvasHide'}>
        <table className='tb'>
          <tbody>
            <tr>
              <td>
                <div className='hamMenu'>
                  <ul>
                    <li>
                      <div className='hamMenuSep'></div>
                    </li>
                    <li>
                      <div className='hamMenuDiv  flex items-center space-x-3'>
                        <svg
                          className='menuIcon'
                          width='64px'
                          height='20px'
                          x='0px'
                          y='20px'
                          viewBox='0 0 512 512'
                        >
                          <g>
                            <g>
                              <path d='M437.019,74.98C388.667,26.629,324.38,0,256,0C187.619,0,123.331,26.629,74.98,74.98C26.628,123.332,0,187.62,0,256 s26.628,132.667,74.98,181.019C123.332,485.371,187.619,512,256,512c68.38,0,132.667-26.629,181.019-74.981 C485.371,388.667,512,324.38,512,256S485.371,123.333,437.019,74.98z M256,482C131.383,482,30,380.617,30,256S131.383,30,256,30 s226,101.383,226,226S380.617,482,256,482z'></path>
                            </g>
                          </g>
                          <g>
                            <g>
                              <path d='M378.305,173.859c-5.857-5.856-15.355-5.856-21.212,0.001L224.634,306.319l-69.727-69.727 c-5.857-5.857-15.355-5.857-21.213,0c-5.858,5.857-5.858,15.355,0,21.213l80.333,80.333c2.929,2.929,6.768,4.393,10.606,4.393 c3.838,0,7.678-1.465,10.606-4.393l143.066-143.066C384.163,189.215,384.163,179.717,378.305,173.859z'></path>
                            </g>
                          </g>
                        </svg>
                        <select
                          className='hamMenuDropdown'
                          value={ciudad.id}
                          onChange={handleCiudadChange}
                        >
                          {ciudades.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.ciudad}
                            </option>
                          ))}
                        </select>
                      </div>
                    </li>
                    {zonas.length > 1 && (
                      <li>
                        <div className='hamMenuDiv flex items-center space-x-3'>
                          <svg
                            className='menuIcon'
                            x='0px'
                            y='0px'
                            viewBox='0 0 390 390'
                            width='64px'
                            height='20px'
                          >
                            <g>
                              <g>
                                <path d='M362.072,63c0.1-0.3-0.1-0.6-0.1-0.9V10c0.2-5.3-4-9.8-9.4-10c-0.1,0-0.2,0-0.3,0h-314.7c-5.3,0-9.7,4.4-9.6,9.7 c0,0.1,0,0.2,0,0.3v52.1c0,0.3-0.2,0.6-0.1,0.9c0,0.1-0.1,0.3,0,0.4c0,0.2,0,0.4,0,0.5c0,0.2,0,0.3,0.1,0.5 c0.1,0.2,0.1,0.3,0.1,0.4c0,0.1,0.1,0.3,0.1,0.5c0,0.1,0.1,0.3,0.1,0.4c0.1,0.2,0.1,0.3,0.2,0.5c0.1,0.1,0.1,0.3,0.2,0.4 c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.3,0.2,0.4c0,0.1,0.2,0.3,0.2,0.4c0.1,0.1,0.2,0.3,0.3,0.4s0.2,0.2,0.2,0.3 c0,0,0.2,0,0.3,0.1l119.9,141V380c-0.1,3.8,2,7.3,5.5,9c1.3,0.6,2.7,1,4.2,1c2.3,0,4.5-0.8,6.2-2.2l70.6-57.3 c2.3-1.9,3.6-4.8,3.5-7.8V209.6l119.8-141c0,0,0.1,0,0.2-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.3,0.3-0.4s0.2-0.3,0.3-0.4 c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.1,0.1-0.3,0.1-0.4 c0.1-0.2,0.1-0.3,0.1-0.5s0.1-0.3,0.1-0.4c0-0.1,0.1-0.3,0.1-0.5c0-0.2,0.1-0.4,0.1-0.5C362.172,63.3,361.972,63.1,362.072,63z M222.572,199.4c-1.6,1.8-2.5,4.1-2.6,6.5v112l-50,41.1V205.9c-0.1-2.4-1-4.7-2.6-6.5L59.172,72h271.6L222.572,199.4z M341.972,52 h-294V20h294V52z'></path>
                              </g>
                            </g>
                          </svg>
                          <select
                            className='hamMenuDropdown'
                            value={zona.id}
                            onChange={handleZonaChange}
                          >
                            {zonas.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.descripcion}
                              </option>
                            ))}
                          </select>
                        </div>
                      </li>
                    )}
                    <li>
                      <div className='hamMenuSep'></div>
                    </li>
                    <li>
                      <Link to="/app/buzon">
                        <div className='hamMenuDiv  flex items-center space-x-3'>
                          <svg className='menuIcon' width="64" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.484 11.976L22.635 6.632V17.259L16.484 11.976ZM8.558 12.881L10.718 14.756C11.057 15.044 11.499 15.218 11.982 15.218H11.999H12.012C12.496 15.218 12.938 15.043 13.281 14.753L13.278 14.755L15.438 12.88L22.004 18.519H1.995L8.558 12.881ZM1.986 5.365H22.016L12.395 13.721C12.291 13.803 12.159 13.853 12.015 13.853C12.01 13.853 12.006 13.853 12.001 13.853C11.997 13.853 11.993 13.853 11.988 13.853C11.844 13.853 11.711 13.803 11.607 13.72L11.608 13.721L1.986 5.365ZM1.365 6.631L7.515 11.975L1.365 17.255V6.631ZM22.965 4.19C22.725 4.07 22.443 4 22.144 4H1.859C1.559 4.001 1.275 4.071 1.024 4.197L1.035 4.192C0.417 4.501 0 5.129 0 5.855V5.859V18.027C0.001 19.053 0.833 19.884 1.858 19.885H22.141C23.167 19.884 23.998 19.052 23.999 18.027V5.859C23.999 5.858 23.999 5.857 23.999 5.855C23.999 5.128 23.58 4.498 22.97 4.195L22.959 4.19H22.965Z" fill="#FBFBFB" />
                          </svg>
                             Enviar Mensaje
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </td>
              <td className='scapeArea' onClick={toggleMenu}>
                <div></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SidebarMenu
