import React, { useState, useEffect } from 'react'
import '../css/SearchResult.css'
import waitImg from '../img/waiting5.gif'

function SearchResult({ comercios, loading }) {
  // Método para truncar texto
  const truncateText = (text, maxLength = 200) => {
    if (!text) return ''
    if (text.length <= maxLength) return text

    // Truncar al último espacio antes de maxLength
    const truncated = text.slice(0, maxLength)
    return truncated.slice(0, truncated.lastIndexOf(' ')) + '...'
  }

  // Renderizar lista de comercios
  const renderComercios = () => {
    if (comercios && comercios.results.length > 0) {
      return (
        <div>
          <ol>
            {comercios.results.map((item) => (
              <li className='resultList' key={item.id}>
                <div className='resultListBox'>
                  <div className='empresa'>{item.empresa}</div>
                  <div>{truncateText(item.servicios)}</div>
                  <br />
                  <div>
                    {item.calle_numero} {item.zona}
                  </div>
                  <div>
                    {item.telefono1} {item.telefono2} {item.telefono3}
                  </div>
                </div>
              </li>
            ))}
          </ol>
          {loading && (
            <div className='waitingDivCom'>
              <img className='waitingImg' src={waitImg} alt='Cargando...' />
            </div>
          )}
        </div>
      )
    } else if (loading) {
      return (
        <div className='waitingDivCom'>
          <img className='waitingImg' src={waitImg} alt='Cargando...' />
        </div>
      )
    } else {
      return (
        <div className='waitingDivCom'>
          <p>No Hay coincidencias</p>
        </div>
      )
    }
  }
  return <div>{renderComercios()}</div>
}

export default SearchResult
