import { useEffect, useState, useRef } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom'
import SidebarMenu from './mod/SidebarMenu'
import SearchBox from './mod/SearchBox'
import Error from './mod/Error'
import SearchResult from './mod/SearchResult'
import BuzonSugerencias from './mod/BuzonSugerencias'

function App() {
  const [path, setPath] = useState(decodeURI(window.location.pathname))
  const [ciudades, setCiudades] = useState([])
  const [ciudad, setCiudad] = useState({})
  const [zonas, setZonas] = useState([])
  const [zona, setZona] = useState({})
  const [texto, setTexto] = useState('')
  const [comercios, setComercios] = useState({ results: [] })
  const [loading, setLoading] = useState(true)
  // Variables en tiempo real
  const contadorRef = useRef(1)
  const lastPathRef = useRef(null)
  const pathRef = useRef('')
  const loadingRef = useRef(false)
  const grupoRef = useRef(0)
  const gr_tamRef = useRef(15)
  const foreceUpdateRef = useRef(false)
  // Constantes
  const render_offset = 250

  const loadInit = async (m_path = path) => {
    try {
      setLoading(true)
      let paths = m_path.split('/').filter((aux_tmp) => {
        return aux_tmp !== ''
      })
      let ciudad_ini = null
      let zona_ini = null
      let new_path = []
      let aux_text = ''

      // Solicitar ciudades
      const responseCiudades = await fetch(
        `${window.infoConfig.apiUrl}/ciudades`
      )
      if (!responseCiudades.ok) {
        throw new Error(
          `Error en la solicitud: ${responseCiudades.status} ${responseCiudades.statusText}`
        )
      }
      const ciudades = await responseCiudades.json()

      // Actualiza ciudad_ini con la ciudad que viene en la ruta
      if (paths.length >= 2) {
        const response = await fetch(
          `${window.infoConfig.apiUrl}/ciudades?pais=${paths[0].replace(/-/g, ' ')}&ciudad=${paths[1].replace(/-/g, ' ')}`
        )
        if (!response.ok) {
          throw new Error(
            `Error en la solicitud: ${response.status} ${response.statusText}`
          )
        }
        const responseJson = await response.json()
        if (Array.isArray(responseJson) && responseJson.length > 0) {
          ciudad_ini = responseJson[0]
          new_path = paths.slice(2)
        }
      }
      // Si lo que viene en la ruta no es una ciudad o la que viene no se encuentra carga ciudad IP
      if (!ciudad_ini) {
        const response = await fetch(
          `${window.infoConfig.apiUrl}/ciudades/by_client_ip`
        )
        if (!response.ok) {
          throw new Error(
            `Error en la solicitud: ${response.status} ${response.statusText}`
          )
        }
        const responseJson = await response.json()
        if (Array.isArray(responseJson) && responseJson.length > 0) {
          ciudad_ini = responseJson[0]
          new_path = paths
        }
      }
      let ciudad = ciudad_ini

      // Solicitar zonas de la ciudad inicial
      const responseZonas = await fetch(
        `${window.infoConfig.apiUrl}/ciudades/${ciudad_ini.id}/zonas`
      )
      if (!responseZonas.ok) {
        throw new Error(
          `Error en la solicitud: ${responseZonas.status} ${responseZonas.statusText}`
        )
      }
      let zonas = await responseZonas.json()

      // Capitalizar la descripción de cada zona antes de guardarla en el estado
      zonas = zonas.map((zona) => ({
        ...zona,
        descripcion: capitalizarTexto(zona.descripcion)
      }))

      // Agregar "Todas las Zonas" al inicio de las zonas
      zonas.unshift({ id: 0, descripcion: 'Todas las Zonas' })

      zona_ini = zonas.find((zona) => zona.id === 0)

      // Busca si el tercer parámetro es la zona
      if (new_path.length > 0) {
        const zonaEncontrada = zonas.find(
          (zona) =>
            zona.descripcion.toLowerCase() ===
            new_path[0].toLowerCase().split('-').join(' ')
        )
        if (zonaEncontrada) {
          zona_ini = zonaEncontrada
          new_path = new_path.slice(1)
        }
      }
      let zona = zona_ini

      aux_text = new_path.join(' ').split('-').join(' ')
      let build_path = linkBuilder(aux_text, ciudad_ini, zona_ini)

      window.history.replaceState({}, '', build_path)

      setPath(build_path)
      setCiudades(ciudades)
      setCiudad(ciudad)
      setZonas(zonas)
      setZona(zona)
      setTexto(aux_text)

      contadorRef.current = -1
    } catch (errMsg) {
      // this.setState({
      //   redirect: true,
      //   txt_error: errMsg.message || 'Error desconocido'
      // })
    }
  }
  const capitalizarTexto = (texto) => {
    return texto
      .toLowerCase()
      .split(' ')
      .map((palabra) => palabra.charAt(0).toUpperCase() + palabra.slice(1))
      .join(' ')
  }
  const linkBuilder = (texto_m = texto, ciudad_m = ciudad, zona_m = zona) => {
    if (!ciudad_m || !ciudad_m.pais || !ciudad_m.ciudad) {
      return null
    }

    let aux = `/${ciudad_m.pais.split(' ').join('-')}/${ciudad_m.ciudad.split(' ').join('-')}`

    if (zona_m && zona_m.id !== 0 && zona_m.descripcion) {
      aux += `/${zona_m.descripcion.split(' ').join('-')}`
    }

    if (texto_m && texto_m.trim() !== '') {
      aux += `/${texto_m.split(' ').join('-')}`
    }

    return aux
  }
  const onSearchTextChanged = (n_texto) => {
    pathRef.current = linkBuilder(n_texto)
    setTexto(n_texto)
    contadorRef.current = -10
  }
  const onCiudadChange = (ciudadID) => {
    setLoading(true)
    setComercios({ results: [] })
    foreceUpdateRef.current = true
    fetch(`${window.infoConfig.apiUrl}/ciudades/${ciudadID}/zonas`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Error en la solicitud: ${response.status} ${response.statusText}`
          )
        }
        return response.json() // Parseamos la respuesta JSON
      })
      .then((data) => {
        // Capitalizar la descripción de cada zona
        const zonasCapitalizadas = data.map((zona) => ({
          ...zona,
          descripcion: capitalizarTexto(zona.descripcion)
        }))

        zonasCapitalizadas.unshift({
          id: 0,
          descripcion: 'Todas las Zonas'
        })

        const selectedCiudad = ciudades.find((item) => item.id === ciudadID)
        const selectedZona = zonasCapitalizadas.find((zona) => zona.id === 0)
        setCiudad(selectedCiudad)
        setZonas(zonasCapitalizadas)
        setZona(selectedZona)
      })
      .catch((errMsg) => {
        console.log(`Error${errMsg}`)
        // this.setState({
        //   redirect: true,
        //   txt_error: errMsg
        // })
      })
  }
  const onZonaChange = (zonaID) => {
    const selectedZona = zonas.find((zona) => zona.id === zonaID)
    setZona(selectedZona)
  }
  const onClearClick = () => {
    if (texto !== '') {
      pathRef.current = linkBuilder('')
      setTexto('')
      contadorRef.current = -1
    }
  }
  const loadSearch = async (
    n_grupo = null,
    n_text = texto,
    n_ciudad_id = ciudad.id,
    n_zona_id = zona.id
  ) => {
    foreceUpdateRef.current = false
    let gr_aux = grupoRef.current

    let text = n_text.replace(/[/\-]/g, ' ').trim()
    let n_path = linkBuilder(text)
    
    if (lastPathRef.current === n_path && n_grupo !== null) return

    try {
      if (n_grupo !== null) gr_aux = n_grupo + 1
      else gr_aux += 1

      const response = await fetch(
        `${window.infoConfig.apiUrl}/comercios/lista?ciudad_id=${n_ciudad_id == '0' ? '' : n_ciudad_id}&zona_id=${n_zona_id == 0 ? '' : n_zona_id}&text=${text}&page=${gr_aux}&per_page=${gr_tamRef.current}`
      )
      if (!response.ok) {
        throw new Error(
          `Error en la solicitud: ${response.status} ${response.statusText}`
        )
      }

      const obj = await response.json()
      lastPathRef.current = n_path

      if (gr_aux === 1) {
        // Primera carga, simplemente asigna el objeto recibido
        setComercios(obj)
      } else {
        // Para siguientes cargas, fusionar resultados previos con los nuevos
        setComercios((prevState) => {
          let consolidado = {
            ...prevState, // 🔹 Mantiene page, per_page, count
            results: [...(prevState.results || []), ...obj.results] // 🔹 Concatena results correctamente
          }
          return consolidado
        })
      }
      grupoRef.current = gr_aux
    } catch (errMsg) {
      console.error('Error al recuperar comercios:', errMsg)
    } finally {
    }
  }
  const onPanellClose = () => {
    let newPath = linkBuilder(texto)
    if (pathRef.current !== newPath){      
      setPath(newPath)
      contadorRef.current = -1  
    }
  }
  const onScroll = async (e) => {
    let element = e.target

    if (
      element.scrollHeight - element.scrollTop - element.clientHeight <=
      render_offset
    ) {
      let still_loading = comercios.count > comercios.results.length
      if (!loadingRef.current && still_loading) {
        loadingRef.current = true
        setLoading(true)
        await loadSearch().then(() => {
          loadingRef.current = false
          setLoading(false)
        })
      }
    }
  }

  // Al cargar el formulario
  useEffect(() => {
    if (/^\/app/i.test(path)) return
    loadInit() // Ejecuta al cargar el componente
    loadingRef.current = false
  }, [])
  // configura texto con el path inicial
  useEffect(() => {
    if (/^\/app/i.test(path)) return
    const handlePopState = () => {
      loadInit(window.location.pathname) // Cargar datos según la URL actual
      window.history.replaceState({}, '', window.location.pathname) // Mantiene la opción de "ir adelante"
    }

    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [])
  // Agrega una nueva entrada al historial
  const updateURL = (newPath) => {
    if (window.location.pathname !== newPath) {
      window.history.pushState({}, '', newPath) 
    }
  }
  // Intervalo de tiempo
  useEffect(() => {
    const interval = setInterval(async () => {
      
      if (contadorRef.current === 0) {
        let build_path = linkBuilder()
        if (build_path !== null && !loadingRef.current) {
          if (foreceUpdateRef.current || !/^\/app/i.test(pathRef.current)){ // Evita recargar la pagina cuando la pagina anterior es app/*
            loadingRef.current = true
            setLoading(true)
            await setComercios({ results: [] })
            await loadSearch(0).then(() => {
              loadingRef.current = false
              setLoading(false)
            })
          }
          updateURL(build_path)
          pathRef.current = build_path
        }
      }
      contadorRef.current = contadorRef.current + 1
    }, 50)

    return () => clearInterval(interval)
  }, [ciudad, zona, texto])

  return (
    <Router>
      <Routes>
        {/* Rutas normales con SidebarMenu y SearchBox */}
        <Route
          path="/*"
          element={
            <div className="main">
              <div className="topBarDiv">
                <table className="topBar">
                  <tbody>
                    <tr>
                      <td className="iconCell">
                        <SidebarMenu
                          ciudad={ciudad}
                          ciudades={ciudades}
                          zona={zona}
                          zonas={zonas}
                          onCiudadChange={onCiudadChange}
                          onZonaChange={onZonaChange}
                          onPanellClose={onPanellClose}
                        />
                      </td>
                      <td>
                        <SearchBox
                          texto={texto}
                          onSearchTextChanged={onSearchTextChanged}
                          onClearClick={onClearClick}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="contenido" onScroll={onScroll}>
                <Routes>
                  <Route path="/error" element={<Error />} />
                  <Route path="*" element={<SearchResult comercios={comercios} loading={loading} />} />
                </Routes>
              </div>
            </div>
          }
        />
        {/* Ruta exclusiva para el buzón sin SidebarMenu ni SearchBox */}
        <Route path="/app/buzon" element={<BuzonSugerencias pathRef={pathRef} lastPathRef={lastPathRef}/>} />
      </Routes>
    </Router>
  );
}

export default App
