import { useEffect, useState, StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import Loading from './Loading'
import './css/main.css'

const container = document.getElementById('root')
const root = createRoot(container)

const Index = () => {
  const [configLoaded, setConfigLoaded] = useState(false)

  useEffect(() => {
    const loadConfig = async () => {
      try {
        const response = await fetch('/config.json')
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const config = await response.json()
        window.infoConfig = config
        setConfigLoaded(true)
      } catch (error) {
        console.error('Failed to load config:', error)
      }
    }
    loadConfig()
  }, [])

  if (!configLoaded) {
    return <Loading />
  }

  return (
    <StrictMode>
      <App />
    </StrictMode>
  )
}

root.render(<Index />)
