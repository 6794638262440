import PropTypes from 'prop-types'
import errorImg from '../img/Error.png'

function Error({ txt_error }) {
  return (
    <div id='Error'>
      <img
        className='imgError'
        src={errorImg}
        width='100px'
        height='100px'
        alt='Error'
      />
      <p className='txtError'>
        Algo anormal ha ocurrido. Perdone las molestias
      </p>
      <br />
      <p className='txtError'>El último mensaje que recibimos dice:</p>
      <br />
      <p className='txtError'>{txt_error}</p>
    </div>
  )
}

Error.propTypes = {
  txt_error: PropTypes.string.isRequired
}

export default Error
