function SearchBox({ texto, onSearchTextChanged, onClearClick }) {
  return (
    <div className='searchForm'>
      <input
        className='searchInput'
        autoFocus
        placeholder='Buscar...'
        value={texto} // Conectado al estado en App
        onChange={(e) => onSearchTextChanged(e.target.value)} // Actualiza el estado
      />
      <div className='svgClear' onClick={() => onClearClick()}>
        <svg width='32px' height='32px' viewBox='0 0 24 24' stroke='none'>
          <path d='M8.3,15.7C8.5,15.9,8.7,16,9,16s0.5-0.1,0.7-0.3l2.3-2.3l2.3,2.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3   c0.4-0.4,0.4-1,0-1.4L13.4,12l2.3-2.3c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0L12,10.6L9.7,8.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4   l2.3,2.3l-2.3,2.3C7.9,14.7,7.9,15.3,8.3,15.7z' />
          <path d='M12,21c5,0,9-4,9-9s-4-9-9-9s-9,4-9,9S7,21,12,21z M12,5c3.9,0,7,3.1,7,7s-3.1,7-7,7s-7-3.1-7-7S8.1,5,12,5z' />
        </svg>
      </div>
    </div>
  )
}

export default SearchBox
